import React from "react";
// import { Link } from "gatsby"
// import Logo from "../images/logo.png";
import Navbar from "./Navbar/Navbar"

const Header = () => {

  return (<header>
    {/* <div className="search">SEARCH</div> */}
    <Navbar />
  </header>)
}

export default Header
